$(function(){

  var headerHeight = ($(window).innerWidth() <= 767) ? 60 : 0;

  $(window).on('resize',function(){
    var width = $(window).innerWidth();
    if(width <= 767) {
      headerHeight = 60;
    } else {
      headerHeight = 0;
    }
  })

  $(".js-nav-btn").on('click',function(){
    $('#l-nav').fadeToggle(300);
    if($(this).hasClass('is-open')) {
      $(this).removeClass('is-open')
    } else {
      $(this).addClass('is-open')
    }
  })
  $("#l-nav").on('click',function(){
    $('#l-nav').fadeOut(300);
    $(".js-nav-btn").removeClass('is-open')
  });

  $('a[href^="#"]').click(function(){
    let speed = 500;
    let href= $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top - headerHeight;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  $('.wp_ulike_counter_up').on('click',function(){
    $(this).prev('.wp_ulike_btn').trigger('click');
  })

})
